const TradeLogo =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">\n' +
  "    <defs>\n" +
  '        <linearGradient id="unname_gra_40" x1="81.64" y1="418.36" x2="418.36" y2="81.64" gradientUnits="userSpaceOnUse">\n' +
  '            <stop offset="0" stop-color="#c7924c"/>\n' +
  '            <stop offset="0.54" stop-color="#ffe37f"/>\n' +
  '            <stop offset="0.79" stop-color="#efbf5d"/>\n' +
  '            <stop offset="1" stop-color="#e09f3e"/>\n' +
  "        </linearGradient>\n" +
  "    </defs>\n" +
  '    <path fill="url(#unname_gra_40)"\n' +
  '          d="M473.69,249.89c9.55-8.85,33.14-35.14,24.22-71.75-5.09-20.91-16.53-37.23-33.09-47.18-18.36-11-42.38-13.55-67.62-7.08-16.21,4.14-36.35,18.2-55.82,34.75C357.93,139.16,372,119,376.12,102.8c6.47-25.24,3.95-49.26-7.08-67.62-10-16.56-26.27-28-47.19-33.1C285.24-6.82,259,16.81,250.11,26.31,241.22,16.73,214.9-6.9,178.32,1.94,157.41,7,141.11,18.41,131.19,35c-11,18.34-13.47,42.35-7,67.61,3.87,15,16.22,34.51,36.29,58-23.45-20.06-42.94-32.4-58-36.28-25.26-6.52-49.27-4-67.61,7C18.43,141.1,7,157.41,1.94,178.32c-8.84,36.58,14.79,62.9,24.37,71.79C16.86,259-6.82,285.25,2.08,321.85c5.1,20.92,16.54,37.24,33.1,47.19,18.36,11,42.38,13.55,67.62,7.08,13-3.32,47.28-21,61.83-34.57,12.11-11.27,43.39-38.59,48.3-94.21l-67.72.07,104.9-104.9L354.79,247.19l-67.61.07C287,305.54,249,349.93,216.61,373.83c-19.31,14.26-45.92,31.39-95.82,41.1-1.7,18.61,1.76,35.89,10.17,49.89,9.95,16.56,26.27,28,47.18,33.09,36.61,8.92,62.93-14.73,71.75-24.22,8.89,9.58,35.21,33.21,71.79,24.37,14.85-3.6,27.38-10.4,36.94-20a67.86,67.86,0,0,0,10.19-13.05c11-18.34,13.47-42.35,6.95-67.61-3.88-15-16.22-34.52-36.28-58,23.45,20.07,42.94,32.42,58,36.29,25.26,6.52,49.27,4,67.61-6.95a67.86,67.86,0,0,0,13.05-10.19c9.56-9.56,16.36-22.09,20-36.94C506.9,285.1,483.27,258.78,473.69,249.89Z"/>\n' +
  "</svg>";

const SpaceLogo =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500.96 500">\n' +
  "    <style>\n" +
  "        .cls-1 { fill: #0f6; }\n" +
  "        .cls-2 { fill: #fff; }\n" +
  "    </style>\n" +
  '    <path class="cls-1"\n' +
  '          d="m453.8,265.65c-15.14-8.96-40.58-13.47-67.37-15.64,26.79-2.17,52.23-6.7,67.37-15.66,23.59-13.94,39.58-33.64,45.04-55.46,4.92-19.68,1.29-40.29-10.48-59.61-20.61-33.79-57.71-35.74-71.39-35.28.52-13.71-1.49-50.8-35.28-71.39-19.32-11.77-39.93-15.4-59.61-10.48-21.82,5.45-41.52,21.44-55.46,45.03-8.29,14.03-13.61,37.67-16.13,69.98-2.52-32.3-7.83-55.94-16.13-69.98-13.94-23.59-33.64-39.58-55.46-45.03-19.66-4.91-40.29-1.28-59.61,10.48-33.79,20.6-35.79,57.69-35.28,71.39-13.62-.45-50.79,1.49-71.39,35.28C.84,138.6-2.78,159.21,2.13,178.89c5.45,21.82,21.45,41.52,45.04,55.46,15.14,8.96,40.59,13.49,67.39,15.66-26.79,2.17-52.24,6.68-67.39,15.64-23.59,13.94-39.58,33.64-45.04,55.46-4.92,19.68-1.29,40.29,10.48,59.6,20.6,33.8,57.77,35.79,71.39,35.28-.52,13.71,1.49,50.8,35.28,71.39,13.72,8.36,28.09,12.61,42.32,12.61,5.8,0,11.59-.71,17.29-2.13,21.82-5.45,41.52-21.44,55.46-45.03,8.3-14.04,13.61-37.68,16.13-69.98,2.52,32.31,7.83,55.95,16.13,69.98,13.94,23.59,33.64,39.58,55.46,45.03,5.7,1.42,11.48,2.13,17.29,2.13,14.23,0,28.6-4.25,42.32-12.61,33.79-20.6,35.79-57.69,35.28-71.39,13.69.52,50.79-1.48,71.39-35.28,11.77-19.31,15.4-39.92,10.48-59.6-5.45-21.82-21.45-41.52-45.04-55.46Z"/>\n' +
  '    <path class="cls-2"\n' +
  '          d="m250.48,173.3c-51.51,0-95.77,31.55-115.24,76.7,19.46,45.15,63.72,76.7,115.24,76.7s95.77-31.55,115.24-76.7c-19.46-45.15-63.72-76.7-115.24-76.7Z"/>\n' +
  '    <path d="m250.48,184.81c-27.54,0-51.04,17.1-60.59,41.24l40.29,23.95-40.29,23.95c9.55,24.14,33.05,41.24,60.59,41.24,36,0,65.19-29.19,65.19-65.19s-29.19-65.19-65.19-65.19Z"/>\n' +
  '    <ellipse class="cls-2" cx="276.77" cy="220.43" rx="16.32" ry="21.33" transform="translate(-74.8 260.27) rotate(-45)"/>\n' +
  "</svg>";

const BootUtil = (function () {
  const BootUtil = {};

  var Settings = {
    minimum: 0.08,
    speed: 200,
  };

  BootUtil.positionUsing = "";

  BootUtil.delayTimer = null;

  BootUtil.status = null;

  BootUtil.set = function (n) {
    var started = typeof BootUtil.status === "number";
    n = clamp(n, Settings.minimum, 1);
    BootUtil.status = n === 1 ? null : n;
    var progress = BootUtil.render(!started),
      bar = progress.querySelector('[role="bar"]'),
      speed = Settings.speed;
    // eslint-disable-next-line
    progress.offsetWidth;
    queue(function (next) {
      if (BootUtil.positionUsing === "")
        BootUtil.positionUsing = BootUtil.getPositioningCSS();
      css(bar, barPositionCSS(n, speed));
      if (n === 1) {
        css(progress, { transition: "none", opacity: 1 });
        // eslint-disable-next-line
        progress.offsetWidth;
        setTimeout(function () {
          css(progress, {
            transition: "all " + speed + "ms linear",
            opacity: 0,
          });
          setTimeout(function () {
            BootUtil.remove();
            next();
          }, speed);
        }, speed);
      } else {
        setTimeout(next, speed);
      }
    });
    return this;
  };

  BootUtil.start = function (delayMs) {
    BootUtil.delayTimer = setTimeout(() => {
      if (!BootUtil.status) BootUtil.set(0);
      var work = function () {
        setTimeout(function () {
          if (!BootUtil.status) return;
          BootUtil.inc();
          work();
        }, 200);
      };
      work();
    }, delayMs);
    return this;
  };

  BootUtil.done = function (force) {
    if (BootUtil.delayTimer) {
      clearTimeout(BootUtil.delayTimer);
    }
    if (!force && !BootUtil.status) return this;
    return BootUtil.inc(0.3 + 0.5 * Math.random()).set(1);
  };

  BootUtil.inc = function (amount) {
    var n = BootUtil.status;
    if (!n) {
      return BootUtil.start();
    } else if (n > 1) {
      return;
    } else {
      if (typeof amount !== "number") {
        if (n >= 0 && n < 0.2) {
          amount = 0.1;
        } else if (n >= 0.2 && n < 0.5) {
          amount = 0.04;
        } else if (n >= 0.5 && n < 0.8) {
          amount = 0.02;
        } else if (n >= 0.8 && n < 0.99) {
          amount = 0.005;
        } else {
          amount = 0;
        }
      }
      n = clamp(n + amount, 0, 0.994);
      return BootUtil.set(n);
    }
  };

  BootUtil.render = function (fromStart) {
    if (!!document.querySelector("#progress"))
      return document.querySelector("#progress");
    var progress = document.createElement("div");
    progress.id = "progress";
    const encodedSVG = encodeURIComponent(
      process.env.TARGET === "trade" ? TradeLogo : SpaceLogo,
    );
    progress.style.backgroundImage = `url('data:image/svg+xml;charset=UTF-8,${encodedSVG}')`;
    progress.innerHTML =
      '<div class="bar" role="bar"><div class="peg"></div></div>';
    var bar = progress.querySelector('[role="bar"]'),
      perc = fromStart ? "-100" : toBarPerc(BootUtil.status || 0),
      parent = document.body;
    css(bar, {
      transition: "all 0 linear",
      transform: "translate3d(" + perc + "%,0,0)",
    });
    parent.appendChild(progress);
    return progress;
  };

  BootUtil.remove = function () {
    var progress = document.querySelector("#progress");
    progress && removeElement(progress);
  };

  BootUtil.getPositioningCSS = function () {
    var bodyStyle = document.body.style;
    var vendorPrefix =
      "WebkitTransform" in bodyStyle
        ? "Webkit"
        : "MozTransform" in bodyStyle
          ? "Moz"
          : "msTransform" in bodyStyle
            ? "ms"
            : "OTransform" in bodyStyle
              ? "O"
              : "";
    if (vendorPrefix + "Perspective" in bodyStyle) {
      return "translate3d";
    } else if (vendorPrefix + "Transform" in bodyStyle) {
      return "translate";
    } else {
      return "margin";
    }
  };

  function clamp(n, min, max) {
    if (n < min) return min;
    if (n > max) return max;
    return n;
  }

  function toBarPerc(n) {
    return (-1 + n) * 100;
  }

  function barPositionCSS(n, speed) {
    var barCSS;
    if (BootUtil.positionUsing === "translate3d") {
      barCSS = { transform: "translate3d(" + toBarPerc(n) + "%,0,0)" };
    } else if (BootUtil.positionUsing === "translate") {
      barCSS = { transform: "translate(" + toBarPerc(n) + "%,0)" };
    } else {
      barCSS = { "margin-left": toBarPerc(n) + "%" };
    }
    barCSS.transition = "all " + speed + "ms linear";
    return barCSS;
  }

  var queue = (function () {
    var pending = [];

    function next() {
      var fn = pending.shift();
      if (fn) {
        fn(next);
      }
    }

    return function (fn) {
      pending.push(fn);
      if (pending.length === 1) next();
    };
  })();

  var css = (function () {
    var cssPrefixes = ["Webkit", "O", "Moz", "ms"],
      cssProps = {};

    function camelCase(string) {
      return string
        .replace(/^-ms-/, "ms-")
        .replace(/-([\da-z])/gi, function (match, letter) {
          return letter.toUpperCase();
        });
    }

    function getVendorProp(name) {
      var style = document.body.style;
      if (name in style) return name;
      var i = cssPrefixes.length,
        capName = name.charAt(0).toUpperCase() + name.slice(1),
        vendorName;
      while (i--) {
        vendorName = cssPrefixes[i] + capName;
        if (vendorName in style) return vendorName;
      }
      return name;
    }

    function getStyleProp(name) {
      name = camelCase(name);
      return cssProps[name] || (cssProps[name] = getVendorProp(name));
    }

    function applyCss(element, prop, value) {
      prop = getStyleProp(prop);
      element.style[prop] = value;
    }

    return function (element, properties) {
      var args = arguments,
        prop,
        value;
      if (args.length === 2) {
        for (prop in properties) {
          value = properties[prop];
          if (value !== undefined && properties.hasOwnProperty(prop))
            applyCss(element, prop, value);
        }
      } else {
        applyCss(element, args[1], args[2]);
      }
    };
  })();

  function removeElement(element) {
    element && element.parentNode && element.parentNode.removeChild(element);
  }

  return BootUtil;
})();

export default BootUtil;
